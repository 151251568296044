// ***************************************************************  All Power BI Dashboards  ***************************************************************

export const dashboards = {
  // Sales Prophet
  salesProphet: {
    marketIntelligence: {
      artOfThePossible: {
        reportId: "d1db97bb-cd5c-4888-b15e-c2736a53b992",
      },
    },
    chapterMemberSegementation: {
      artOfThePossible: {
        reportId: "93728b60-abbc-4a04-887c-82cc42b8bfb4",
      },
    },
    activitiesAndEngagement: {
      artOfThePossible: {
        reportId: "5a7d6abe-2ad8-4adb-9a52-5cc0a644aee6",
      },
    },
    salesAndAgentProductivity: {
      artOfThePossible: {
        reportId: "86eed161-f59b-4e89-8de6-62380bec9673",
      },
    },
    salesGrowthOpportunity: {
      artOfThePossible: {
        reportId: "a05a426d-6305-49c8-9c18-f9ff391590ec",
      },
    },
    fraternalSalesEffectiveness: {
      artOfThePossible: {
        reportId: "156b2b3d-dc23-4901-8d1a-e598438c703a",
      },
    },
  },
};
