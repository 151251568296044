import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { UserContext } from "../../context/userContext";
import API from "../../api/api";
import jwtDecode from "jwt-decode";
import { LuLoader2 } from "react-icons/lu";

const initialInputData = {
  username: "",
  password: "",
  rememberMe: false,
};

const Login = ({ focus, setFocus }) => {
  const {
    setIsUserLoggedIn,
    setLoggedInUserData,
    saveBeforePathPBI,
    userActivityTracker,
  } = useContext(UserContext);
  const [showPass, setShowPass] = useState(false);
  const [inputData, setInputData] = useState(initialInputData);
  const [userPassErrors, setUserPassErrors] = useState(null);
  const [usernameRequired, setUsernameRequired] = useState(null);
  const [passwordRequired, setPasswordRequired] = useState(null);
  const [otherErrors, setOtherErrors] = useState(null);
  const [load, setLoad] = useState(false);
  const [isUsernameFocused, setIsUsernameFocused] = useState(false);

  const navigate = useNavigate();

  const resetErrors = () => {
    if (userPassErrors || usernameRequired || passwordRequired || otherErrors) {
      setUserPassErrors(null);
      setUsernameRequired(null);
      setPasswordRequired(null);
      setOtherErrors(null);
    }
  };

  // login function
  const handleOnLogin = (e) => {
    e.preventDefault();
    setLoad(true);
    resetErrors();

    // Clear logins
    if (inputData.username && inputData.password) {
      // if (inputData.rememberMe) {
      // Remember Me is On i.e Login way is JWT.
      API.post("/modernwoodmen/login", inputData)
        .then((response) => {
          setLoad(false);
          if (response.status === 200 && response.data) {
            const accessToken = response.data.accessToken;
            localStorage.setItem(
              "mwa_user_info",
              JSON.stringify({ accessToken })
            );
            const decodedToken = jwtDecode(accessToken);
            setIsUserLoggedIn(true);
            setLoggedInUserData({
              id: decodedToken.id,
              company: decodedToken.company,
              username: decodedToken.username,
              fname: decodedToken.fname,
              lname: decodedToken.lname,
              role: decodedToken.role,
              email: decodedToken.email,
              phone: decodedToken.phone,
              picture: decodedToken.picture,
            });
            userActivityTracker(
              "User logged in",
              "Login page",
              inputData.username,
              decodedToken.company
            );
            if (saveBeforePathPBI) {
              navigate(saveBeforePathPBI);
            } else {
              navigate("/home");
            }
          }
        })
        .catch((error) => {
          setLoad(false);
          userActivityTracker(
            "User tried to log in but failed",
            "Login page",
            inputData.username
          );
          // console.log(error);
          if (error?.response?.data?.errorReponse) {
            setUserPassErrors(error?.response?.data?.errorReponse);
            return;
          } else {
            setOtherErrors("Something went wrong!");
            setTimeout(() => {
              setOtherErrors(null);
            }, 5000);
          }
        });
      // } else {
      //   // Remember Me is Off i.e Login way is Session Loggin.
      //   API.post("/modernwoodmen/session-login", inputData)
      //     .then((response) => {
      //       setLoad(false);
      //       if (response.status === 200 && response.data.userLoggedIn) {
      //         setIsUserLoggedIn(true);
      //         setLoggedInUserData({
      //           company: response.data.userInfo.company,
      //           username: response.data.userInfo.username,
      //           role: response.data.userInfo.role,
      //           fname: response.data.userInfo.fname,
      //           lname: response.data.userInfo.lname,
      //           id: response.data.userInfo.id,
      //           email: response.data.userInfo.email,
      //           phone: response.data.userInfo.phone,
      //           picture: response.data.userInfo.picture,
      //         });
      //         navigate("/home");
      //       }
      //     })
      //     .catch((error) => {
      //       setLoad(false);
      //       // console.log(error);
      //       if (error?.response?.data?.errorReponse) {
      //         setUserPassErrors(error?.response?.data?.errorReponse);
      //         return;
      //       } else {
      //         setOtherErrors("Something went wrong!");
      //       }
      //     });
      // }
    } else {
      if (!inputData.username) {
        setUsernameRequired("Username is required");
      }
      if (!inputData.password) {
        setPasswordRequired("Password is required");
      }
      setLoad(false);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "rememberMe") {
      setInputData({ ...inputData, [e.target.name]: e.target.checked });
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value });
      resetErrors(null);
    }
  };

  // Function to mask the username
  const maskUsername = (username) => {
    if (username.length === 0) {
      return;
    }
    if (username.includes("@")) {
      const parts = username.split("@");
      const firstTwo = parts[0].slice(0, 2);
      const lastTwo = parts[0].slice(-2);
      return `${firstTwo}${"*".repeat(5)}${lastTwo}@${parts[1]}`;
    }
    const firstTwo = username.slice(0, 2);
    const lastTwo = username.slice(-2);
    return `${firstTwo}${"*".repeat(5)}${lastTwo}`;
  };

  return (
    <form action="" onSubmit={(e) => handleOnLogin(e)} className="select-none">
      {/* Username field */}
      <div className="my-10 relative z-0 text-sm">
        <input
          onChange={(e) => handleInputChange(e)}
          type="text"
          name="username"
          id="username"
          className={`block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-green-500 peer ${
            userPassErrors || usernameRequired
              ? "border-red-500"
              : "border-gray-500"
          }`}
          value={
            isUsernameFocused
              ? inputData.username
              : maskUsername(inputData.username)
          }
          placeholder=""
          autoFocus="on"
          onFocus={() => {
            setFocus(true);
            setIsUsernameFocused(true);
          }}
          onBlur={() => {
            setIsUsernameFocused(false);
          }}
        />
        <label
          htmlFor="username"
          className={`absolute select-none border-gray-500 text-black font-semibold duration-300 transform origin-[0] top-3 ${
            inputData.username || focus
              ? "scale-75 -translate-y-6"
              : "scale-100 peer-focus:scale-75 peer-focus:-translate-y-6"
          }`}
        >
          Username or email
        </label>
        {userPassErrors && (
          <p className="text-red-500 text-sm">{userPassErrors}</p>
        )}
        {usernameRequired && (
          <p className="text-red-500 text-sm">{usernameRequired}</p>
        )}
      </div>
      {/* Password field */}
      <div className="my-10 relative z-0 text-sm">
        <input
          name="password"
          onChange={(e) => handleInputChange(e)}
          type={showPass ? "text" : "password"}
          id="password"
          className={`block py-2.5 px-0 w-full text-black bg-transparent border-0 border-b-2 appearance-none focus:outline-none focus:ring-0 focus:border-green-500 peer ${
            userPassErrors || passwordRequired
              ? "border-red-600 dark:border-red-500"
              : "border-gray-500"
          }`}
          placeholder=""
        />
        <div
          onMouseDown={() => setShowPass(true)}
          onMouseUp={() => setShowPass(false)}
          title="Hold To Peek"
          className={`${showPass ? "cursor-grabbing" : "cursor-grab"}`}
        >
          {inputData.password.length > 0 &&
            (showPass ? (
              <FaRegEyeSlash className="absolute top-5 right-0" />
            ) : (
              <FaRegEye className="absolute top-5 right-0" />
            ))}
        </div>
        <label
          htmlFor="password"
          className={`absolute select-none border-gray-500 text-black font-semibold duration-300 transform origin-[0] top-3  ${
            inputData.password || focus
              ? "scale-75 -translate-y-6"
              : "scale-100 peer-focus:scale-75 peer-focus:-translate-y-6"
          }`}
        >
          Password
        </label>
        {userPassErrors && (
          <p className="text-red-500 text-sm">{userPassErrors}</p>
        )}
        {passwordRequired && (
          <p className="text-red-500 text-sm">{passwordRequired}</p>
        )}
      </div>

      <div className="flex gap-5 md:gap-0 justify-between items-center text-xs font-semibold mt-2 mb-8">
        {/* Remember me checkbox */}
        <div className="flex gap-2 items-center">
          <input
            type="checkbox"
            name="rememberMe"
            id="rememberMe"
            className="w-4 h-4 focus:outline-none focus:ring-0"
            onChange={(e) => handleInputChange(e)}
            checked={inputData.rememberMe || ""}
          />
          <label htmlFor="rememberMe">Remember Me</label>
        </div>
        <Link to="/forgot-password" className="hover:underline">
          Forgot Password?
        </Link>
      </div>
      {/* Sign in and forgot password options */}
      {load ? (
        <div className="flex items-center gap-2 w-full p-2 justify-center rounded-md bg-gray-300 cursor-not-allowed">
          <span className="animate-spin">
            <LuLoader2 />
          </span>
          <span>Signing in...</span>
        </div>
      ) : (
        <input
          type="submit"
          className="w-full p-2 text-white rounded-md hover:shadow-xl bg-tertiary cursor-pointer"
          onClick={(e) => handleOnLogin(e)}
          value="Sign in"
        />
      )}
      {/* Other Error e.g connection lost, Db Errors etc. */}
      {otherErrors && (
        <p className="text-red-500 text-sm pt-2">{otherErrors}</p>
      )}
    </form>
  );
};

export default Login;
