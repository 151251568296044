// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards } from "./dashboards";
import { FaGlobeAmericas, FaUserFriends } from "react-icons/fa";
import {
  FaAddressCard,
  FaUserSecret,
  FaMagnifyingGlassDollar,
  FaUserTie,
} from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { TbWorldDollar } from "react-icons/tb";

// ***************************************************************   All Industries  ***************************************************************
export const deployment2024 = {
  title: "2024 Deployment",
  slug: "/2024-deployment",
  name: "deployment2024",
};

export const artOfThePossible = {
  title: "Art of the Possible",
  slug: "/art-of-the-possible",
  name: "Art of the Possible",
};

// ***************************************************************   All Solutions  ***************************************************************
// Sales Prophet
export const salesProphet = {
  title: "Sales Prophet",
  slug: "/sales-prophet",
  // slug: "#",
  icon: <FaGlobeAmericas />,
  tagline: "Art of the Possible",
  description:
    "Art of the Possible analysis for the following in-scope regions: Texas (N), Texas (NC), Mississippi, Florida (SW)",
  deployments: [artOfThePossible],
  artOfThePossible: [
    {
      title: "Market Intelligence",
      slug: "/market-intelligence",
      icon: <TbWorldDollar />,
      description:
        "Overview of MWA regions and counties relevant to life products, detailing market size, sales performance, and wallet share",
      // "Market size, MWA sales, and wallet share for in-scope products and regions",
      dashboards: { ...dashboards.salesProphet.marketIntelligence },
    },
    {
      title: "Sales & Agent Productivity",
      slug: "/sales-and-agent-productivity",
      // slug: "#",
      icon: <FaUserTie />,
      description:
        "Insights into MWA sales segmented by customer demographics, alongside an overview of agent performance and productivity",
      // "Agent performance overview and productivity dashboard",
      dashboards: { ...dashboards.salesProphet.salesAndAgentProductivity },
    },
    {
      title: "Chapter & Member Segmentation",
      slug: "/chapter-and-member-segmentation",
      icon: <FaAddressCard />,
      description:
        "Dashboard providing insights into the landscape and segmentation of chapters and members",
      // "Demographic analysis of chapter membership and leadership",
      dashboards: { ...dashboards.salesProphet.chapterMemberSegementation },
    },
    {
      title: "Activities & Engagement",
      slug: "/activities-and-engagement",
      icon: <FaUserFriends />,
      description:
        "Evaluation of chapter activities, spend, attendance, and their overall effectiveness",
      // "Analysis of chapter events, participation, and effectiveness",
      dashboards: { ...dashboards.salesProphet.activitiesAndEngagement },
    },
    {
      title: "Fraternal/Sales Effectiveness",
      slug: "/fraternal-sales-effectiveness",
      // slug: "#",
      icon: <MdOutlineSettingsSuggest />,
      description:
        "Assessment of chapter effectiveness based on leadership and premier status",
      dashboards: { ...dashboards.salesProphet.fraternalSalesEffectiveness },
    },
    {
      title: "Sales & Growth Opportunity",
      slug: "/sales-and-growth-opportunity",
      // slug: "#",
      icon: <FaMagnifyingGlassDollar />,
      description: "High-level sales opportunities for in-scope MWA regions",
      dashboards: { ...dashboards.salesProphet.salesGrowthOpportunity },
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const home = [salesProphet];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...home,
];
