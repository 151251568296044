import React, { useContext, useEffect, useState } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import {
  breadCrumbs,
  salesProphet,
  ar,
  artOfThePossible,
} from "../../data/data";
import FilterDeployment from "../../components/modules/FilterDeployment";
import { selectedDeployment } from "../../data/staticData";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const SalesProphet = () => {
  const { setPageTitle } = useContext(UserContext);
  const [deployment, setDeployment] = useState(
    localStorage.getItem("salesprophet-deployment-title") &&
      localStorage.getItem("salesprophet-deployment-value")
      ? {
          title: localStorage.getItem("salesprophet-deployment-title"),
          slug: localStorage.getItem("salesprophet-deployment-value"),
        }
      : {
          title: salesProphet.deployments[0].title,
          slug: salesProphet.deployments[0].slug,
        }
  );

  useEffect(() => {
    setPageTitle(salesProphet.title + ": " + deployment.title);
  }, [deployment]);

  return (
    <section className="p-5 pt-0 w-full md:min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(salesProphet)} />
        <FilterDeployment
          deployments={salesProphet.deployments}
          setDeployment={setDeployment}
          selected={selectedDeployment(deployment)}
          prefix={"salesprophet-"}
        />
      </div>
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out items-center">
        <div className="md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:w-3/4 h-full gap-5 items-center justify-center transition-all duration-300 ease-in-out">
          {deployment?.title === artOfThePossible.title &&
            salesProphet.artOfThePossible?.map((module, index) => (
              <div
                key={index}
                className="relative grid p-2 h-full min-h-[12rem] rounded-lg gap-1 items-center text-xs  transition-all duration-300 ease-in-out"
              >
                <Link
                  to={
                    module.slug === "#"
                      ? "#"
                      : salesProphet.slug + artOfThePossible.slug + module.slug
                  }
                  className="w-full h-full p-10 flex flex-col gap-2 relative hover:text-white border hover:border-primary bg-primary bg-opacity-5 hover:bg-opacity-100 rounded-2xl shadow-lg"
                >
                  <span className="absolute text-4xl top-2 right-3 opacity-10">
                    {salesProphet.icon}
                  </span>
                  <div className="flex gap-2 items-center">
                    <span className="p-1 rounded-full text-2xl w-fit">
                      {module.icon}
                    </span>
                    <h3 className="font-semibold text-sm">{module.title}</h3>
                  </div>
                  <p className="">{module.description}</p>
                </Link>
              </div>
            ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default SalesProphet;
