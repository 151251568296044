import React from "react";
import Select from "react-select";
import { colourStyles } from "../utils/Functions";
import { createTimelineOptions } from "../../data/staticData";

const FilterDeployment = ({ deployments, selected, setDeployment, prefix }) => {
  const onChangeSelect = (e) => {
    localStorage.setItem(prefix + "deployment-title", e.label);
    localStorage.setItem(prefix + "deployment-value", e.value);
    setDeployment({
      title: e.label,
      slug: e.value,
    });
  };

  return (
    <div className="text-xs flex flex-col sm:flex-row gap-2 sm:items-center text-[11px] md:text-xs">
      <label htmlFor="timeline">Select Deployment:</label>
      <Select
        styles={colourStyles}
        name="timeline"
        options={createTimelineOptions(deployments)}
        className="w-40 lg:w-60"
        onChange={(e) => onChangeSelect(e)}
        defaultValue={selected}
        classNamePrefix={"timeline"}
      />
    </div>
  );
};

export default FilterDeployment;
