import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";
const clientLogoWhite =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/modern_woodmen/modern_woodmen-logo.svg";
const clientLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/modern_woodmen/modern_woodmen-logo.svg";
const bg =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/auth/login-background-image.webp";

const AuthTemplate = () => {
  const [page, setPage] = useState(null);
  const { pathname } = useLocation();
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (pathname === "/login") {
      setPage("Login");
    } else if (pathname === "/forgot-password") {
      setPage("ForgotPassword");
    }
  });

  return (
    <>
      <section
        className="sm:grid place-items-center h-screen relative"
        onClick={() => setFocus(false)}
      >
        <div className="flex flex-col sm:flex-row items-center justify-center relative h-full md:h-4/5 lg:h-5/6 xl:4/5 w-full md:w-10/12 md:rounded-xl md:shadow-2xl md:border-t md:border-r sm:overflow-hidden">
          {/* Banners for Desktop*/}
          <div className="sm:bg-primary relative flex items-center justify-center w-1/2 md:w-3/5 h-fit sm:h-full">
            {/* <img src={bg} alt="white lines" className="absolute z-[1] blur" /> */}
            <img
              src={clientLogoWhite}
              alt="image"
              className="w-2/3 sm:absolute z-[2]"
            />
          </div>
          {/* Form Container */}
          <div className="h-fit sm:h-full relative w-full sm:w-1/2 xl:w-2/5 sm:shadow-2xl bg-white flex items-center">
            {page !== "Login" && (
              <div className="fixed sm:absolute top-10 right-5 text-sm">
                <Link
                  to="/login"
                  className="p-2 text-white rounded-md bg-primary hover:bg-white hover:text-primary border-2 border-primary"
                >
                  Back to login
                </Link>
              </div>
            )}
            {/* Forms */}
            <div className="m-3 sm:m-0 sm:px-5 xl:px-20 w-full scale-75 xl:scale-100">
              <div className="flex flex-col gap-3 justify-center">
                <h1 className="text-2xl font-extrabold">
                  {page === "Login" && "User Login"}
                  {page === "ForgotPassword" && "Reset your password"}
                </h1>
              </div>
              {page === "Login" && <Login focus={focus} setFocus={setFocus} />}
              {page === "ForgotPassword" && <ForgotPassword />}
            </div>
            {/* Powered By Spinnaker Analytics */}
            <div className="absolute right-10 bottom-10 w-32 hidden md:flex flex-col items-end scale-75 xl:scale-90">
              <p className="text-[10px] 2xl:text-xs">Powered by</p>
              <img
                src={spinnakerLogo}
                alt="powered by spinnaker analytics"
                className="w-28"
              />
            </div>
          </div>
        </div>

        {/* Powered By Spinnaker Analytics */}
        <div className="absolute right-2 bottom-5 w-32 flex md:hidden flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </section>
      {/* Show on small devices */}
      {/* <section className="fixed top-0 lg:hidden h-full w-full flex flex-col gap-5 items-center justify-center p-10 text-center text-[10px]">
        <img src={clientLogo} alt="spinnaker logo" className="w-40" />
        Looks like you are using or small screen. <br /> Please switch to a
        laptop or a wider screen to use the portal. <br /> If you are using an
        ipad or tablet switch to landscape mode.
        <div className="w-full flex flex-col items-center scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </section> */}
    </>
  );
};

export default AuthTemplate;
